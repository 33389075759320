define('garage/locales/fi/translations', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = {
        weekday: {
            Monday: 'Maanantai',
            Tuesday: 'Tiistai',
            Wednesday: 'Keskiviikko',
            Thursday: 'Torstai',
            Friday: 'Perjantai',
            Saturday: 'Lauantai',
            Sunday: 'Sunnuntai'
        },

        languages: {
            fi: 'Suomi',
            en: 'Englanti',
            sv: 'Ruotsi',
            de: 'Saksa'
        },

        dropdown: {
            select: 'Valitse...'
        },

        toggle: {
            capacity: 'Use vehicle specific values',
            showInactive: 'Show expired and not yet started shifts.',
            Alcolock: 'Alcolock',
            'Easy access': 'Easy access'
        },

        // ---------------

        button: {
            Delete: 'Poista',
            Copy: 'Kopioi',
            New: 'Uusi',
            Revert: 'Palauta',
            Save: 'Tallenna',
            SaveAsType: 'Tallenna ajoneuvotyyppinä',
            SaveAsGroup: 'Tallenna ryhmänä',
            SaveAll: 'Tallenna',
            Join: 'Liity',
            Add: 'Lisää',
            Edit: 'Muokkaa',
            Remove: 'Poista',
            Cancel: 'Peruuta',
            Login: 'Kirjaudu sisään',
            Logout: 'Kirjaudu ulos',
            CopyToVehicle: 'Kopioi autolle',
            GenQRCode: 'Luo QR-koodi',
            Select: 'Valitse'
        },

        capacity: {
            wheelchair: 'Pyörätuoli',
            ambulatory: 'Kävelevä',
            luggage: 'Matkatavara',
            walker: 'Rollaattori',
            dog: 'Koira'
        },

        tab: {
            Vehicle: 'Ajoneuvo',
            Capacity: 'Kapasiteetit',
            Attributes: 'Attribuutit',
            Shift: 'Vuorot',
            Garage: 'Asemapaikka',
            Terminal: 'Terminaali',

            Info: 'Info',
            Vehicles: 'Autot',
            Operators: 'Yritykset',
            Drivers: 'Kuljettajat',
            VehicleReports: 'Reittiraportti',
            OperatorReports: 'Autoraportti',
            Settings: 'Asetukset',

            Users: 'Käyttäjät',
            UserManagement: 'Käyttäjien hallinta',
            OtherSettings: 'Muut asetukset',

            LicenseSettings: 'Lisenssiasetukset'
        },

        form: {
            label: {
                Name: 'Nimi',
                Operator: 'Yritys',
                Fleet: 'Yksikkö',
                CallId: 'Autonumero',
                ExternalId: 'Ulkoinen autonumero',
                PaymentTerminalId: 'Maksupäätetunnus',
                Description: 'Kuvaus',
                DispatchParameters: 'Välitys',
                DispatchArea: 'Alue',
                DispatchPriority: 'Prioriteetti',
                LicenceNumber: 'Rekisterinumero',
                Phone: 'Puhelin',
                PriceFactor: 'Hintakerroin',
                EmissionFactor: 'Päästökerroin',
                ServiceFactor: 'Palvelukerroin',
                VehicleType: 'Ajoneuvotyyppi',
                Attributes: 'Attribuutit',
                Capacities: 'Lisää uusi kapasiteetti',
                ExistingCapacities: 'Auton kapasiteetit',
                ShiftGroups: 'Ajovuororyhmät',
                Customization: 'Kustomointi',
                Username: 'Käyttäjä',
                Password: 'Salasana',
                ShiftTemplates: 'Ajovuoropohjat',
                ShiftTemplateInterval: 'Voimassaoloaika',
                UseShift: 'Käytä ajovuoroa',

                UserEmail: 'Käyttäjän sähköposti',
                UserPassword: 'Käyttäjän salasana',
                CompanyName: 'Yrityksen nimi',
                CompanyAddress: 'Yrityksen osoite',
                ContactName: 'Yhteyshenkiön nimi',
                ContactPhone: 'Yhteyshenkilön puhelin',
                ContactEmail: 'Yhteyshenkilön sähköposti',
                BusinessId: 'Y-tunnus',
                OperatorInfo: 'Lisätiedot (näkyy vain hallinnoijille)',
                OperatorExternalId: 'Ulkoinen tunniste',
                Iban: 'Pankkitili (IBAN)',
                Bic: 'Pankkiosoite (BIC)',

                Email: 'Sähköposti',
                PinCode: 'PIN-koodi',
                DriverExternalId: 'Ulkoinen tunniste',
                LicenseAccepted: 'Käyttösopimus hyväksytty',
                Status: 'Status',
                Role: 'Rooli',
                Permissions: 'Oikeudet',

                AddUser: 'Lisää uusi käyttäjä',
                AllUsers: 'Kaikki käyttäjät',
                Verified: 'Varmennettu',
                VerifiedText: 'Jätä tyhjäksi, mikäli varmennus sähköpostin kautta',
                Unlink: 'Poista',
                Contract: 'Sopimus',

                driver: {
                    status: {
                        active: 'Aktiivinen',
                        inactive: 'Ei aktiivinen'
                    }
                }
            },
            help: {
                DispatchArea: 'Pilkulla eroteltu lista alueiden tunnisteita. e.g. Alue1,Alue2. Tyhjä arvo=Kaikki alueet',
                DispatchPriority: 'Mitä isompi arvo sitä suurempi todennäköisyys saada reittitarjouksia. 0=ei tarjouksia, 100=oletuspriotiteetti',
                PriceFactor: 'Hintakerroin joitakin autonvalintastrategioita varten.',
                EmissionFactor: 'Päästökerroin',
                ServiceFactor: 'Palvelukerroin',
                UseShift: 'Tilauksia välitetään vain ajovuorossa. Oletuksena autolle välitetään aina.'
            },
            text: {
                Passengers: 'Matkustajia',
                Wheelchair: 'Pyörätuoleja',
                WalkingAid: 'Rollaattoreita',
                SafetySeat: 'Turvaistuimia',
                Alcolock: 'Alkolukko',
                AllAreas: 'Kaikki alueet'
            },
            errors: {
                'Value is required': 'Arvo ei voi olla tyhjä.'
            },
            capacities: {
                ambulatory: 'Matkustaja',
                wheelchair: 'Pyörätuoli',
                luggage: 'Matkalaukku',
                'assistive-device': 'Apuväline',
                stretcher: 'Paarivarustus',
                'front-seat': 'Etupenkki'
            },
            attributes: {
                'test-vehicle': 'Testiauto',
                bus: 'Tila-auto',
                'station-wagon': 'Farmari',
                sedan: 'Henkilöauto',
                'eco-friendly': 'Ympäristöystävällinen',
                paratransit: 'Paratransit',
                lowrider: 'Matalalattia',
                'pet-friendly': 'Kuljettaa eläimiä',
                'non-smoking': 'Hajusteeton auto',
                stretcher: 'Paarivarustus',
                stairclimber: 'Porrasveto',
                'front-seat': 'Etupenkki',
                'wheelchair-lift': 'Pyörätuolihissi',
                'multiple-wheelchairs': 'Tilava auto',
                'electric-wheelchair': 'Sähköpyörätuoli',
                alcometer: 'Alkometri'
            },
            placeholder: {
                lookup: 'hae...',
                alias: 'alias',
                filter: 'rajaa',
                searchAndAdd: 'etsi ja lisää',
                noUser: 'ei käyttäjää'
            }
        },

        message: {
            title: {
                success: 'Onnistui',
                failure: 'Virhe',
                saving: 'Tallentaa...'
            },
            text: {
                wait: 'Ole hyvä ja odota!',

                vehicle: {
                    delete: {
                        success: 'Auto poistettiin onnistuneesti.',
                        failure: 'Auton poistaminen epäonnistui.'
                    },
                    save: {
                        failure: 'Auton tallentaminen epäonnistui.'
                    },
                    new: {
                        failure: 'Uuden auton luominen epäonnistui.'
                    },
                    copy: {
                        failure: 'Auton kopioiminen epäonnistui.'
                    }
                },
                shift: {
                    new: {
                        failure: 'Uuden ajovuoron luominen epäonnistui.'
                    }
                },
                operator: {
                    delete: {
                        success: 'Yritys poistettiin onnistuneesti.',
                        failure: 'Yrityksen poistaminen epäonnistui.'
                    },
                    save: {
                        failure: 'Yrityksen tallentaminen epäonnistui.'
                    },
                    new: {
                        failure: 'Uuden yrityksen luominen epäonnistui.'
                    },
                    copy: {
                        failure: 'Yrityksen kopioiminen epäonnistui.'
                    }
                },
                driver: {
                    delete: {
                        success: 'Kuljettaja poistettiin onnistuneesti.',
                        failure: 'Kuljettajan poistaminen epäonnistui.'
                    },
                    save: {
                        failure: 'Kuljettajan tallennus epäonnistui.',
                        operatorMandatory: 'Kuljettajan yritys asetettava.'
                    },
                    new: {
                        failure: 'Uuden kuljettajan luominen epäonnistui.'
                    },
                    copy: {
                        failure: 'Kuljettajan kopiointi epäonnistui.'
                    }
                },
                user: {
                    save: {
                        success: 'Käyttäjä tallennettu.',
                        failure: 'Käyttäjän tallennus epäonnistui.'
                    }
                }
            }
        },

        dialog: {
            button: {
                yes: 'Kyllä',
                no: 'Ei',
                close: 'Sulje'
            },
            saved: {
                title: 'Tallennus',
                text: {
                    success: 'Ei virheitä tallennuksessa.',
                    withErrors: 'Seuraavat virheet havaittiin tallennuksessa: {{errors}}',
                    driverToOperatorError: '<br>-<b>{{name}}</b>: {{error}}',
                    driver: 'Onnistui kuljettajille: {{success}} yhteensä: {{totalSuccess}}<br>Epäonnistui kuljettajille {{failed}}',
                    vehicle: 'Onnistui autoille: {{success}} (yhteensä: {{totalSuccess}})<br>Epäonnistui autoille: {{failed}}',
                    operator: 'Onnistui yrityksille: {{success}} (yhteensä: {{totalSuccess}})<br>Epäonnistui yrityksille: {{failed}}'
                }
            },
            delete: {
                title: 'Poista {{name}}',
                text: 'Oletko varma?'
            },
            revert: {
                title: 'Peruuta kaikki muutokset',
                text: 'Oletko varma?'
            },
            shiftCopy: {
                copyTitle: 'Kopioi ajovuoropohja'
            },
            saveLicense: {
                title: 'Tallenna käyttöehdot kielellä {{language}}',
                text: 'Oletko varma että haluat tallentaa?'
            },
            changeLangNoSave: {
                title: 'Poistu tallentamatta',
                text: 'Oletko varma että haluat vaihtaa kieltä? Tallentamattomat muutoksesi katoavat'
            },
            vehicleRange: {
                title: 'Valitse autot',
                placeholder: 'tyhjä (kaikki), 1000-1200, 1502'
            }
        },

        messages: {
            loginFailed: 'Kirjautuminen epäonnistui.',
            vehicle: {
                'data.attributes.name': 'Nimi virheellinen',
                'data.attributes.number': 'Autonumero virheellinen'
            },
            shiftTpl: {
                'overlapping.shift.template': 'Päällekkäinen ajovuoropohja',
                'no.weekdays.interval': 'Yksikään päivä ei osu aikavälille',
                'data.attributes.startTime': 'Aloituspäivämäärä virheellinen',
                'data.attributes.endTime': 'Lopetuspäivämäärä virheellinen',
                'data.attributes.startDay': 'Aloituspäivä annettava',
                'data.attributes.endDay': 'Lopetuspäivä annettava',
                overNightShiftCopyError: 'Yön yli ajovuoron kopiointi ei tällä hetkellä tuettu.',
                vehicleHasNoUseShift: 'Auto ei käytä ajovuoroja'
            }
        },

        table: {
            shift: {
                columns: {
                    startDay: 'Aloituspäivä',
                    startTime: 'Aloitusaika',
                    endDay: 'Lopetuspäivä',
                    endTime: 'Lopetusaika',
                    from: 'Alku',
                    to: 'Loppu',
                    group: 'Ryhmä',
                    startTimeClock: 'Aloitusaika',
                    endTimeClock: 'Lopetusaika',
                    startTimeDate: 'Alku',
                    endTimeDate: 'Loppu'
                }
            }
        },

        import: {
            operator: {
                title: 'Tuo yritykset',
                unsaved: 'Uudet tai muuttuneet yritykset',
                instructions: 'Pudota yritysten <b><i>mbr-operator*.tsv</i></b> tiedosto tähän'
            },
            vehicle: {
                title: 'Tuo tiedot',
                unsaved: 'Uudet tai muuttuneet autot',
                instructions: 'Pudota autojen <b><i>vehicle*.tsv</i></b> JA <b><i>capacity*.tsv</i></b> tiedostot TAI autojen <b><i>*.json</i></b> tiedosto tähän'
            },
            button: 'Tuo tiedot',
            exportButton: 'Vie tiedot',
            save: 'Tallenna kaikki',
            clear: 'Poista keskeneräiset'
        },

        role: {
            super: 'Ylikäyttäjä',
            admin: 'Hallinnoija',
            operator: 'Yrittäjä'
        },

        report: {
            noshow: 'Hukka-ajo',
            genReport: 'Luo raportti',
            routeId: 'Reitin numero',
            externalRouteId: 'Ulkoinen tunnus',
            vehicleNumber: 'Auton numero',
            vehicleName: 'Auton nimi',
            date: 'Päivämäärä',
            driver: 'Kuljettaja',
            startTime: 'Aloitusaika',
            endTime: 'Lopetusaika',
            startLocation: 'Lähtöosoite',
            endLocation: 'Kohdeosoite',
            noResults: 'Ei tuloksia',
            noVehicles: '-- Ei autoja --',
            routes: 'Reittejä',
            distance: 'Matka',
            price: {
                total: 'Hinta',
                excess: 'Omavastuut',
                extraFees: 'Lisämaksut',
                sum: 'Summa',
                vat: 'ALV:n osuus',
                vatType: 'ALV-%',
                waitFee: 'Odotusmaksu',
                transitFee: 'Luokseajokorvaus'
            }
        },

        etc: {
            license: {
                createdLicenses: 'Olet luonut käyttöehdot seuraaville kielille: {{languages}}.',
                noCreatedLicenses: 'Et ole vielä luonut käyttöehtoja. Aloita valitsemalla kieli'
            }
        },

        customerLocale: {
            tuomi: {
                report: {
                    price: {
                        excess: 'Asiakasmaksut'
                    }
                }
            }
        }
    };
});