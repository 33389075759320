define('garage/vehicle/vehicle/index/route', ['exports', 'file-saver'], function (exports, _fileSaver) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }

            return arr2;
        } else {
            return Array.from(arr);
        }
    }

    exports.default = Ember.Route.extend({
        viewData: Ember.Object.create({
            filterText: '',
            fleets: [],
            operators: [],
            filteredOperators: [],
            selectedOperator: null,
            vehicle: null,
            qrCode: null,
            contracts: [],
            vehicleContracts: []
        }),

        model: function model() {
            var _this = this;

            // Todo: disabled for now
            // this.set('viewData.fleets', this.get('store').findAll('fleet'));
            this.set('viewData.qrCode', null);
            this.set('viewData.vehicle', this.modelFor('vehicle.vehicle').vehicle);

            // load contracts first and operators and set to viewData
            this.get('store').findAll('contract').then(function (contracts) {
                // contracts are ember objects -> convert to plain objects and modify name
                var uiContracts = contracts.map(function (c) {
                    return { key: c.id, name: c.get('name') + ' (' + c.id + ')' };
                });
                _this.set('viewData.contracts', uiContracts);
            });

            this.get('store').findAll('operator').then(function (result) {
                _this.set('viewData.operators', result);
                _this.set('viewData.filteredOperators', _this.get('viewData.operators').filter(Boolean));
                _this.set('viewData.selectedOperator', result.find(function (i) {
                    return _this.get('viewData.vehicle.operator') === +i.get('id');
                }));
            });

            return this.get('viewData');
        },


        actions: {
            selectOperator: function selectOperator(dropdown, operator) {
                dropdown.actions.close();

                this.set('viewData.selectedOperator', operator);

                this.set('viewData.vehicle.operator', operator ? +operator.get('id') : null);
            },
            selectFleet: function selectFleet(option) {
                if (!option) {
                    this.get('viewData.vehicle').set('fleetId', null);
                } else if (parseInt(this.get('viewData.vehicle').get('fleetId')) !== parseInt(option.get('id'))) {
                    this.get('viewData.vehicle').set('fleetId', option.get('id'));
                }
            },
            genQRCode: function genQRCode() {
                var _this2 = this;

                this.get('viewData.vehicle').genQRCode().then(function (response) {
                    _this2.set('viewData.qrCode', response);
                    _fileSaver.saveAs(_this2.get('viewData.qrCode.data'), _this2.get('viewData.qrCode.name'));
                });
            },
            saveQRCode: function saveQRCode() {
                _fileSaver.saveAs(this.get('viewData.qrCode.data'), this.get('viewData.qrCode.name'));
            },
            onContractSelect: function onContractSelect(value) {
                if (!this.get('viewData.vehicle.contract')) {
                    this.get('viewData.vehicle').set('contract', []);
                }

                if (!this.get('viewData.vehicle.contract').find(function (i) {
                    return i === value;
                })) {
                    this.get('viewData.vehicle').set('contract', [].concat(_toConsumableArray(this.get('viewData.vehicle.contract')), [value]));
                }
            },
            onContractRemove: function onContractRemove(value) {
                var removeValue = value.key || value;
                this.get('viewData.vehicle').set('contract', this.get('viewData.vehicle.contract').filter(function (i) {
                    return i !== removeValue;
                }));
            },
            onContractClear: function onContractClear() {
                this.get('viewData.vehicle').set('contract', []);
            }
        },

        vehicleContractObserver: Ember.observer('viewData.vehicle.contract', 'viewData.contracts', function () {
            var contracts = this.get('viewData.contracts') || [];
            var vehicleContracts = this.get('viewData.vehicle.contract') || [];
            var contractObjects = vehicleContracts.map(function (vc) {
                return contracts.find(function (c) {
                    return c.key === vc;
                }) || vc;
            });

            this.set('viewData.vehicleContracts', contractObjects);
        }),

        filterObserver: Ember.observer('viewData.filterText', function () {
            var _this3 = this;

            var filter = this.get('viewData.filterText').toLowerCase();

            this.set('viewData.filteredOperators', this.get('viewData.operators').filter(function (i) {
                return _this3._filterOperator(i, filter);
            }));
        }),

        _filterOperator: function _filterOperator(operator, filter) {
            return ('' + operator.get('companyName')).toLowerCase().includes(filter) || ('' + operator.get('companyAddress')).toLowerCase().includes(filter) || ('' + operator.get('userEmail')).toLowerCase().includes(filter) || ('' + operator.get('contactName')).toLowerCase().includes(filter) || ('' + operator.get('contactPhone')).toLowerCase().includes(filter) || ('' + operator.get('contactEmail')).toLowerCase().includes(filter) || ('' + operator.get('businessId')).toLowerCase().includes(filter);
        }
    });
});